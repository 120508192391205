import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './components/auth/auth.guard';
import { ContextChangeComponent } from './components/context-change/context-change.component';
import { LoginComponent } from './components/login/login.component';
import { MaintenanceComponent } from './components/maintenance/maintenance.component';
import { CreateSessionFeedbackComponent } from './shared/dialogs/create-session-feedback/create-session-feedback.component';

const routes: Routes = [
	{
		path: '',
		pathMatch: 'full',
		redirectTo: '/login',
	},
	{
		path: 'login',
		canActivate: [AuthGuard],
		component: LoginComponent,
	},
	{
		path: 'maintenance',
		component: MaintenanceComponent,
	},
	{
		path: 'changeCtx',
		component: ContextChangeComponent,
	},
	{
		path: 'sessionFeeback',
		component: CreateSessionFeedbackComponent,
	},
	{
		path: 'account-management',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./components/services/account-management/account-management.module').then((m) => m.AccountManagementModule),
	},
	{
		path: 'my-profile',
		canActivate: [AuthGuard],
		loadChildren: () => import('./components/my-profile/myprofile.module').then((m) => m.MyProfileModule),
	},
	{
		path: 'audit-log',
		canActivate: [AuthGuard],
		loadChildren: () => import('./components/services/audit-log/audit-log.module').then((m) => m.AuditLogModule),
	},
	{
		path: 'az-fundamentals',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./components/services/azure-fundamentals/azure-fundamentals.module').then((m) => m.AzureFundamentalsModule),
	},
	{
		path: 'car-rental',
		canActivate: [AuthGuard],
		loadChildren: () => import('./components/services/car-rental/car-rental.module').then((m) => m.CarRentalModule),
	},
	{
		path: 'cmsmgw',
		canActivate: [AuthGuard],
		loadChildren: () => import('./components/cmsmgw/cmsmgw.module').then((m) => m.CmsMgwModule),
	},
	{
		path: 'corc',
		canActivate: [AuthGuard],
		loadChildren: () => import('./components/services/corc/corc.module').then((m) => m.CorcModule),
	},
	{
		path: 'customer-db',
		canActivate: [AuthGuard],
		loadChildren: () => import('./components/services/customer-db/customer-db.module').then((m) => m.CustomerDbModule),
	},
	{
		path: 'dashboard',
		canActivate: [AuthGuard],
		loadChildren: () => import('./components/services/dashboard/dashboard.module').then((m) => m.DashboardModule),
	},
	{
		path: 'dci-ng',
		canActivate: [AuthGuard],
		loadChildren: () => import('./components/services/dci-ng/dci-ng.module').then((m) => m.DciNgModule),
	},
	{
		path: 'domains-dns',
		canActivate: [AuthGuard],
		loadChildren: () => import('./components/services/domains-dns/domains-dns.module').then((m) => m.DomainsDnsModule),
	},
	{
		path: 'drive',
		canActivate: [AuthGuard],
		loadChildren: () => import('./components/services/drive/drive.module').then((m) => m.DriveModule),
	},
	{
		path: 'dynamic-cloud',
		canActivate: [AuthGuard],
		loadChildren: () => import('./components/services/dynamic-cloud/dynamic-cloud.module').then((m) => m.DynamicCloudModule),
	},
	{
		path: 'event-bridge',
		canActivate: [AuthGuard],
		loadChildren: () => import('./components/services/event-bridge/event-bridge.module').then((m) => m.EventBridgeModule),
	},
	{
		path: 'generic-services',
		canActivate: [AuthGuard],
		loadChildren: () => import('./components/services/generic-services/generic-services.module').then((m) => m.GenericServicesModule),
	},
	{ path: 'iam', canActivate: [AuthGuard], loadChildren: () => import('./components/iam/iam.module').then((m) => m.IamModule) },
	{
		path: 'ip-management',
		canActivate: [AuthGuard],
		loadChildren: () => import('./components/services/ip-manangement/ip-manangement.module').then((m) => m.IpManangementModule),
	},
	{
		path: 'managed-gateway',
		canActivate: [AuthGuard],
		loadChildren: () => import('./components/services/managed-gateway/managed-gateway.module').then((m) => m.ManagedGatewayModule),
	},
	{
		path: 'managed-os',
		canActivate: [AuthGuard],
		loadChildren: () => import('./components/services/managed-os/managed-os.module').then((m) => m.ManagedOsModule),
	},
	{
		path: 'managed-windows',
		canActivate: [AuthGuard],
		loadChildren: () => import('./components/services/managed-windows/managed-windows.module').then((m) => m.ManagedWindowsModule),
	},
	{
		path: 'metering-engine',
		canActivate: [AuthGuard],
		loadChildren: () => import('./components/services/metering-engine/metering-engine.module').then((m) => m.MeteringEngineModule),
	},
	{
		path: 'mia-museum',
		canActivate: [AuthGuard],
		loadChildren: () => import('./components/services/mia-museum/mia-museum.module').then((m) => m.MiaMuseumModule),
	},
	{
		path: 'service-registry',
		canActivate: [AuthGuard],
		loadChildren: () => import('./components/services/service-registry/service-registry.module').then((m) => m.ServiceRegistryModule),
	},
	{
		path: 'sms-notifications',
		canActivate: [AuthGuard],
		loadChildren: () => import('./components/services/sms-notifications/sms-notifications.module').then((m) => m.SmsNotifyModule),
	},
	{
		path: 'ssl-monitoring',
		canActivate: [AuthGuard],
		loadChildren: () => import('./components/services/ssl-monitoring/ssl-monitoring.module').then((m) => m.SslMonitoringModule),
	},
	{
		path: 't-rex',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./components/services/resource-explorer/resource-explorer.module').then((m) => m.ResourceExplorerModule),
	},
	{ path: 'vpc', canActivate: [AuthGuard], loadChildren: () => import('./components/services/vpc/vpc.module').then((m) => m.VpcModule) },
	{
		path: 'terraform-management',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./components/services/terraform-management/terraform-management.module').then((m) => m.TerraformManagementModule),
	},
	{
		path: 'simple-vpn',
		canActivate: [AuthGuard],
		loadChildren: () => import('./components/services/simple-vpn/simple-vpn.module').then((m) => m.SimpleVpmModule),
	},
	{
		path: 's3',
		canActivate: [AuthGuard],
		loadChildren: () => import('./components/services/s3/s3.module').then((m) => m.S3Module),
	},
	{
		path: 'service-order',
		canActivate: [AuthGuard],
		loadChildren: () => import('./components/services/service-order/service-order.module').then((m) => m.ServiceOrderModule),
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
